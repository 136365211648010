<template>

  <v-container fluid class="down-top-padding">

    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card v-if="applicationFetch" :heading="application.data.exam_application.name">
      <v-container v-if="applicationFetch" class="px-4">
        <v-row>
          <!-- <span class="math-tex">{{ '\\(x_a+y^2\\)' }}</span> -->
          <v-col cols="12" md="9">
            <p class="title font-weight-medium mb-0">
              <small>Descrição</small>
            </p>
            <v-card class="elevation-0 blue-grey lighten-5 mt-0 mb-3">
              <v-card-text>
                <p v-html="application.description"></p>
              </v-card-text>
            </v-card>
            <p class="title font-weight-medium mt-0 mb-0">
              <small>Instruções</small>
            </p>
            <v-card class="elevation-0 blue-grey lighten-5 mt-0 mb-3">
              <v-card-text>
                <p v-html="application.data.exam_application.instructions"></p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn
              large
              color="primary"
              elevation="0"
              class="w-100"
              @click="startExam()"
              :disabled="isPreviousDate(application.data.exam_application.ends_at) ||
              (application.data.status !== 'STARTED' &&
              application.data.status !== 'NOT_STARTED') ||
              application.access.has_limit && application.access.count >= application.access.limit || getCheckBoxForInep()"
            >
              {{ getButtonMessage() }}
            </v-btn>
            <v-card class="elevation-0 blue-grey lighten-5 mt-4">
              <v-card-text>

                <p class="body-2 font-weight-medium my-0">Turma</p>
                <p class="body-2 mt-0 mb-2">
                  <v-avatar color="primary" size="30" class="mr-1">
                    <img :src="application.course.avatar" />
                  </v-avatar>
                  {{ application.course.name }}
                </p>
                <p class="body-2 font-weight-medium my-0 mt-2">Professor</p>
                <p class="body-2 mt-0 mb-2">
                  <v-avatar color="primary" size="30" class="mr-1">
                    <img :src="application.author.avatar" />
                  </v-avatar>
                  {{ application.author.name }}
                </p>
                <p class="body-2 font-weight-medium my-0 mt-2">Início</p>
                <p class="body-2 my-0">{{ application.starts_at_formated }}</p>
                <p class="body-2 font-weight-medium my-0 mt-2">Término</p>
                <p class="body-2 my-0">{{ application.ends_at_formated }}</p>
                <p class="body-2 font-weight-medium my-0 mt-2">Duração máxima</p>
                <p class="body-2 my-0">{{ application.duration === '0m' ? 'Sem limite' : application.duration }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="organizationInepId === id">
          <v-col class="pt-0" cols="12">
            <v-checkbox v-model="checkTermOfConsent">
              <template v-slot:label>
                <div>
                  Eu concordo com o
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                        class="font-bold"
                        target="_blank"
                        href="https://pruvo.app/termos-de-uso/"
                        @click.stop
                        v-on="on"
                      >
                        termo de consentimento
                      </a>
                    </template>
                    Uma nova janela será aberta
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
       <v-skeleton-loader
          v-else
          v-bind="{boilerplate: true, elevation: 2,}"
          type="card-avatar, article, actions"
        ></v-skeleton-loader>
    </base-card>
    <resolution
      :applicationFetch="applicationFetch"
      ref="appResolution"
      :application="application"
      @warningExit="dialogWarningExit = $event"
      @warningExitLimit="dialogWarningExitLimit = $event"
      @sendLogType="sendLogInputType"
    ></resolution>
    <v-dialog v-model="dialogWarningExit" persistent width="400">
      <v-card>
        <v-card-title class="headline yellow darken-3 white--text">ATENÇÃO</v-card-title>
        <v-card-text class="yellow darken-3 white--text">
          Após iniciar a avaliação é proibido deixar a tela de resolução. Esta
          ação foi registrada e uma nova saída pode resultar na finalização
          automática da prova.
        </v-card-text>
        <v-card-actions class="yellow darken-3">
          <v-spacer></v-spacer>
          <v-btn color="white" outlined @click="dialogWarningExit = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogWarningExitLimit" persistent width="400">
      <v-card>
        <v-card-title class="headline red darken-3 white--text">ATENÇÃO</v-card-title>
        <v-card-text class="red darken-3 white--text">
          Após iniciar a avaliação é proibido deixar a tela de resolução. Você
          atingiu o limite máximo de saídas permitidas e por isso sua prova
          foi encerrada.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="red darken-3">
          <v-spacer></v-spacer>
          <v-btn color="white" outlined @click="dialogWarningExitLimit = false; this.$router.push({ path: '/resolution/list' })">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import Resolution from '../application/Resolution.vue'
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import { compareAsc, parseISO, formatISO } from 'date-fns'
import ls from '@/libs/crypto'

export default {
  components: {
    Resolution
  },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Avaliações',
        disabled: false,
        to: '/resolution/list'
      }
    ],
    formatISO,
    applicationFetch: false,
    application: {},
    id: '',
    dialogWarningExit: false,
    dialogWarningExitLimit: false,
    examData: {
      exam_application: '',
      student_exam_application: '',
      exam: ''
    },
    keys: {
      start: '',
      ended: '',
      value: []
    },
    keysControl: {
      index: 1,
      lastKey: Date.now()
    },
    page: {
      title: 'Aplicações de Prova'
    },
    organizationInepId: '962a6582-f7c6-43eb-b38a-e56dbc499aec',
    checkTermOfConsent: false
  }),
  computed: {
    ...mapGetters('exams', ['examSelected']),
    ...mapState('network', ['online'])
  },
  created () {
    document.addEventListener('keydown', this.pressKey)
    this.keys.start = Date.now()
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.pressKey)
  },
  methods: {
    ...mapMutations(['setDownload']),
    ...mapActions('network', ['sendLog']),
    pressKey (e) {
      if (!e) e = window.event
      let key = e.key
      const keyCode = e.keyCode
      const now = Date.now()
      if (key === 'Dead') return
      key = ((keyCode >= 48 && keyCode <= 122) || keyCode === 32) ? key : `[${key}]`
      if (!this.keys.value.length) {
        this.keys.value = [key]
      } else if ((this.keys.ended + 2000) < now) {
        this.keys.value.push(key)
      } else {
        this.keys.value[this.keys.value.length - 1] =
        (keyCode >= 48 && keyCode <= 122)
          ? `${this.keys.value[this.keys.value.length - 1]}${key}`
          : `${this.keys.value[this.keys.value.length - 1]} ${key} `
      }
      this.keys.ended = now
      this.keysControl.lastKey = now
    },

    isPreviousDate (date) {
      if (compareAsc(new Date(Date.now()), parseISO(date)) === -1) {
        return false
      }
      return true
    },

    filterTitle (item) {
      function striphtml (text) {
        return text.replace(/<[^>]*>?/gm, '').replace('\\(', '').replace('\\)', '').replace(/&nbsp;/g, '')
      }
      const mainText = striphtml(item.main_text.substr(0, 30)).trim()
      const wording = striphtml(item.wordings[0].wording ? item.wordings[0].wording.substr(0, 30) : '').trim()
      if (mainText.length > 10) {
        return mainText
      } else if (wording.length > 10) {
        return wording
      }
      return 'Questão'
    },
    async startExam () {
      try {
        await this.getQuestions()
        this.$refs.appResolution.startExam()
      } catch (error) {

      }
    },
    formatResumeQuestions (questions) {
      const arr = []
      if (!questions.length) return arr
      questions.forEach(value => {
        if (value) {
          const obj = {
            title: this.filterTitle(value),
            answer: false,
            lastSyncAnswer: null
          }
          arr.push(obj)
        }
      })
      return arr
    },
    getButtonMessage () {
      let accessCount = 0
      const lsApplication = ls.get(`questions/${this.application.id}`, { secret: this.application.id })
      if (lsApplication) {
        accessCount = lsApplication.access_count
      }
      switch (true) {
        case this.isPreviousDate(this.application.data.exam_application.ends_at):
          return 'Prova expirada'
        case this.application.data.status === 'NOT_STARTED' && accessCount === 0:
          return 'Iniciar resolução'
        case this.application.access.has_limit && accessCount >= this.application.access.limit:
          return 'Aplicação encerrada'
        case this.application.access.has_limit && accessCount < this.application.access.limit:
        case !this.application.access.has_limit:
          return 'Retomar resolução'
        default:
          return 'Aplicação encerrada'
      }
    },
    async getQuestions () {
      this.setDownload(true)
      this.sending = true
      const self = this
      await this.$axios.post(`/organizations/${this.id}/students/exams-applications/${this.application.metadata.id}/start`)
        .then(response => {
          if (!response.data.success && response.data.message) {
            self.$store.dispatch('alert', { color: 'red', msg: response.data.message })
          }
          const questions = {
            ...response.data.data,
            resumeQuestions: this.formatResumeQuestions(response.data.data.questions)
          } || ls.get(`questions/${this.application.id}`, { secret: this.application.id })
          ls.set(`questions/${this.application.id}`, questions, { secret: this.application.id })
        })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          }
          if (error.response.data.message) {
            self.$store.dispatch('alert', { color: 'red', msg: error.response.data.message })
          } else {
            self.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
          throw new Error()
        })
        .finally(() => {
          this.sending = false
          this.setDownload(false)
        })
    },
    async sendLogInputType () {
      try {
        if (this.keys.value.length) {
          await this.sendLog({
            organization: this.id,
            type: 'INPUT_TYPE',
            until: formatISO(new Date(this.keys.ended)),
            when: formatISO(new Date(this.keys.start)),
            examApplication: this.application.data.exam_application.id,
            studentExamApplication: this.application.id,
            exam: this.application.data.exam_application.exam.id,
            value: JSON.stringify(this.keys.value)
          })
          this.keys = {
            start: this.keys.ended,
            ended: '',
            value: []
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    getCheckBoxForInep () {
      if (this.id !== this.organizationInepId) {
        return false
      } else {
        return !this.checkTermOfConsent
      }
    }
  },
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    const storage = ls.get(`exam/${this.$route.params.id}`, { secret: this.$route.params.id })

    if (storage) {
      this.application = storage
      this.applicationFetch = true
      this.examData = {
        exam_application: this.application.data.exam_application.id,
        student_exam_application: this.application.id,
        exam: this.application.data.exam_application.exam.id
      }
      this.breadcrumbs.push({
        text: this.application.data.exam_application.name,
        disabled: true,
        to: '/'
      })
    }

    setInterval(() => {
      this.sendLogInputType()
    }, 10 * 1000)
  },
  watch: {
    '$vuetify.breakpoint.mobile' (val) {
      this.drawer = !val
    }
  }
}
</script>
<style>
.modal {
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content/Box */
.modal-content {
  position: fixed;
  /* top: calc(50% - 100);
  left: calc(50% - 150px); */
  width: 100%;
  padding: 0 10px;
  height: 300px;
  background-color: transparent;
  z-index: 10000;
}
@media (min-width: 600px) {
  .modal-content {
    position: fixed;
    /* top: calc(50% - 200px);
    left: calc(50% - 150px); */
    width: 400px;
    height: 300px;
    background-color: transparent;
    z-index: 10000;
  }
}
.font-bold {
  font-weight: 500;
}
</style>
