<template>
  <fullscreen
    ref="fullscreen"
    :fullscreen.sync="fullscreen"
    @change="fullscreenChange"
    id="resolution-app"
    class="tw-bg-white tw-overflow-y-scroll"
    allowfullscreen
  >
    <template
      v-if="fullscreen"
    >
      <div class="modal" v-show="dialogFinish" allowfullscreen>
        <div class="modal-content">
          <v-card>
            <v-card-title class="headline primary white--text">Deseja finalizar a prova?</v-card-title>

            <v-card-text class="mt-4">
              <p class="body-2 my-0">Após finalizar a prova, não será mais possível acessá-la.</p>
              <p
                v-show="questionProgress.solved !== questionProgress.total"
                class="body-2 my-0 mt-2"
              >
                <strong>IMPORTANTE:</strong> Você ainda possui questões em
                aberto.
              </p>
              <p
                v-show="questionProgress.solved === questionProgress.total"
                class="body-2 my-0 mt-2"
              >
                Todas as suas questões já foram respondidas.
              </p>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-bind:color="
                  questionProgress.solved != questionProgress.total
                    ? 'primary'
                    : 'grey darken-1'
                "
                text
                @click="dialogFinish = false"
              >Não</v-btn>
              <v-btn
                v-bind:color="
                  questionProgress.solved == questionProgress.total
                    ? 'primary'
                    : 'grey darken-1'
                "
                text
                @click="finishExam"
              >Sim</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
      <v-card v-if="applicationFetch">
        <v-app-bar flat dark fixed color="primary" class="rounded-0">
          <v-app-bar-nav-icon @click="drawer = !drawer" v-if="!mobile"></v-app-bar-nav-icon>
          <v-app-bar-title>{{ application.data.exam_application.name }}</v-app-bar-title>
          <v-spacer></v-spacer>
          <timer-application
            :duration="application.total"
            :startDate="application.data.started_at"
            :status="application.data.status"
            v-if="mobile"
            @finishTimer="finishExam"
          ></timer-application>
          <v-btn icon dark @click="clickExit">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text flat class="rounded-0 pa-0" style="min-height: 100vh !important">
          <v-navigation-drawer
            mobile-breakpoint="960"
            v-model="drawer"
            absolute
            style="padding-top: 64px"
          >
            <template v-slot:prepend>
              <v-list-item two-line>
                <v-list-item-avatar>
                  <img :src="user.me.data.avatar" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ user.me.data.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ user.me.data.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-container>
                <v-btn
                  color="primary"
                  v-bind:outlined="
                    questionProgress.solved != questionProgress.total
                      ? true
                      : false
                  "
                  @click="dialogFinish = true"
                  class="mt-4 mb-8 w-100"
                  elevation="0"
                >Finalizar Prova</v-btn>

                <p class="body-2 my-1">
                  Resoluções {{ questionProgress.solved }} de
                  {{ questionProgress.total }}
                </p>
                <v-progress-linear :value="questionProgress.progress"></v-progress-linear>
                <div class="relative tw-flex tw-justify-end tw-mt-2">
                  <timer-application
                    :navbar="true"
                    :duration="application.total"
                    :status="application.data.status"
                    v-if="!mobile"
                    @finishTimer="finishExam"
                  ></timer-application>
                </div>
              </v-container>
            </template>
            <v-list class="pt-0 mt-7 inner-sidebar">
              <v-list-item-group single v-model="activeResumeQuestion" active-class="info--text">
                <div v-for="(item , index) in dataQuestions.resumeQuestions" :key="`${index}-menuitems`">
                  <v-list-item @click="()=>{clickTitle(index)}">
                    <v-list-item-icon class="mr-3">
                      <CheckboxSvg v-if="item.answer" color="#1e88e5" />
                      <v-icon v-else-if="dataQuestions.questions[index].wordings[0].answer">mdi-checkbox-marked-circle-outline</v-icon>
                      <v-icon v-else color="grey">mdi-dots-horizontal-circle-outline</v-icon>

                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="body-2 font-weight-medium">
                        {{ index + 1 }} - {{ item.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
          <v-main style="min-height: 100vh !important">
            <v-container fluid>
              <base-card :heading="`Questão ${activeQuestion.order +1 }`">
                <form-resolution
                  :item="activeQuestion"
                  :key="activeQuestion.order"
                  :index="activeQuestion.order"
                  :application="application"
                  :resumeQuestions="dataQuestions.resumeQuestions[activeQuestion.order]"
                  @sendAnswer="answerQuestion($event, activeQuestion)"
                />
                <v-container>
                  <!-- {{ mobile }} - {{ drawer }} / {{ activeQuestion.order }} -->
                  <v-row class="pa-4">
                    <v-spacer />
                    <v-btn
                      v-if="activeQuestion.order > 0"
                      @click="gotoPreviousQuestion"
                      color="primary"
                      outlined
                      class="mr-4"
                      elevation="0"
                    >Anterior</v-btn>
                    <v-btn
                      v-if="questionProgress.solved != questionProgress.total"
                      color="primary"
                      @click="gotoNextQuestion"
                      elevation="0"
                    >Próxima</v-btn>
                    <v-btn
                      v-else
                      color="primary"
                      elevation="0"
                      @click="dialogFinish = true"
                    >Finalizar</v-btn>
                  </v-row>
                </v-container>
              </base-card>
            </v-container>
          </v-main>
        </v-card-text>
      </v-card>
    </template>
    <v-snackbar
      :timeout="-1"
      v-model="snackbarOff"
    >
        Sua conexão foi perdida
        <template v-slot:action="{ attrs, on }">
          <v-btn
            color="red"
            text
            v-bind="attrs"
            v-on="on"
            @click="snackbarOff = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar
      :timeout="5000"
      v-model="snackbarOn"
    >
        Sua conexão foi recuperada
        <template v-slot:action="{ attrs, on }">
          <v-btn
            color="info"
            text
            v-bind="attrs"
            v-on="on"
            @click="snackbarOn = false"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
  </fullscreen>
</template>

<script>
import Vue from 'vue'
import fullscreen from 'vue-fullscreen'
import FormResolution from '../../components/forms/FormResolution.vue'
import TimerApplication from '../../components/timer/TimerApplication.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import CheckboxSvg from '../../components/CheckboxSvg.vue'
import { formatISO } from 'date-fns'
import ls from '@/libs/crypto'
import collect from 'collect.js'

export default {

  components: { TimerApplication, FormResolution, CheckboxSvg },
  props: {
    application: {
      type: Object,
      require: true
    },
    applicationFetch: {
      type: Boolean,
      require: false
    }
  },
  data: () => ({
    id: '',
    errorSendAnswer: false,
    activeQuestion: {},
    activeResumeQuestion: 1,
    drawer: true,
    mobile: false,
    activeExam: false,
    dialogWarningExit: false,
    dialogWarningExitLimit: false,
    dialogFinish: false,
    fullscreen: false,
    questionProgress: {
      solved: 0,
      total: null,
      progress: 0
    },
    offlineStart: null,
    dataQuestions: [],
    page: {
      title: 'Aplicações de Prova'
    },
    snackbarOff: false,
    snackbarOn: false,
    intervalSyncAnswer: null
  }),
  computed: {
    ...mapState({
      exam: 'exams/examSelected'
    }),
    ...mapState('network', ['online']),
    ...mapState(['user'])
  },
  methods: {
    ...mapActions('network', ['storageRequests', 'getStorageRequests', 'sendLog']),
    ...mapMutations(['setLoading']),
    handleErrors (errors) {
      collect(errors).each(error => {
        this.$store.dispatch('alert', { color: 'red', msg: error[0] })
      })
    },
    async nowOnline () {
      const when = formatISO(this.offlineStart) || formatISO(Date.now())
      const until = formatISO(Date.now())
      this.sendLogData({ type: 'EXAM_APPLICATION_OFFLINE', when, until })
      const keys = await this.getStorageRequests()
      if (keys && this.errorSendAnswer) {
        this.queueRequest(keys)
      }
    },
    async queueRequest (keys) {
      const self = this
      let promise = Promise.resolve()
      keys.forEach(function (requestName) {
        promise = promise.then(function () {
          return new Promise(function (resolve) {
            const request = localStorage.getItem(requestName)
            self.sendRequestStorage(JSON.parse(request), requestName)
            setTimeout(resolve, 1100)
          })
        })
      })
    },
    async sendLogData ({ type, when, until, wording, question, value }) {
      const params = {
        organization: this.id,
        type,
        examApplication: this.application.data.exam_application.id,
        studentExamApplication: this.dataQuestions.student.metadata.id,
        exam: this.application.data.exam_application.exam.id,
        ...(when) && { when },
        ...(!when) && { when: formatISO(Date.now()) },
        ...(until) && { until },
        ...(value) && { value },
        ...(wording) && { wording },
        ...(question) && { question }
      }
      await this.sendLog(params)
    },
    answerQuestion (event = null, question) {
      this.sendAnswer(question, event)
      this.$emit('sendLogType')
    },
    sendAnswer (question, event = null) {
      if (event && event.length) {
        event.map(answer => {
          question.wordings[answer.id].answer = answer.answer
          const payload = {
            answers: [question.wordings[answer.id].answer]
          }
          const answerId = question.wordings[answer.id].answer_id
          const self = this
          const order = question.order

          this.$axios
            .post(`/organizations/${this.id}/students/exams-applications/${this.dataQuestions.student.metadata.id}/answer/${answerId}`, payload)
            .then(response => {
              if (response.data.success) {
                this.dataQuestions.resumeQuestions[order].answer = payload.answers[0]
              }
            })
            .catch(error => {
              this.errorSendAnswer = true
              this.storageRequests({ answerId, path, payload, order })
              const errosArray = error.response.data.errors
              this.handleErrors(errosArray)
            })
            .finally(() => {
              this.sending = false
            })
        })
        return
      }
      const answers = typeof question.wordings[0].answer !== typeof [] ? [question.wordings[0].answer] : question.wordings[0].answer

      if (!question.wordings[0].answer) return
      const payload = {
        answers: answers
      }
      const answerId = question.wordings[0].answer_id
      const self = this
      const order = question.order

      const path = `/organizations/${this.id}/students/exams-applications/${this.dataQuestions.student.metadata.id}/answer/${answerId}`
      this.$axios
        .post(path, payload)
        .then(response => {
          if (response.data.success) {
            this.dataQuestions.resumeQuestions[order].answer = payload.answers[0]
          }
        })
        .catch(error => {
          this.errorSendAnswer = true
          this.storageRequests({ answerId, path, payload, order })
          const errosArray = error.response.data.errors
          this.handleErrors(errosArray)
        })
        .finally(() => {
          this.sending = false
        })
    },
    sendRequestStorage (request, name) {
      const self = this
      this.$axios
        .post(request.path, request.payload)
        .then(response => {
          if (response.data.success) {
            localStorage.removeItem(name)
            if (request.order) {
              this.dataQuestions.resumeQuestions[request.order].answer = true
            }
          }
        })
        .catch(error => {
          const errosArray = error.response.data.errors
          this.handleErrors(errosArray)
        })
        .finally(() => {
          this.sending = false
        })
    },
    toggle () {
      this.$refs.fullscreen.toggle()
    },
    fullscreenChange () {
      if (!this.$refs.fullscreen.isFullscreen) {
        this.clickExit()
        this.recordExit()
        setTimeout(() => {
          location.reload()
        }, 2000)
      }
    },
    startExam () {
      this.activeExam = true
      this.fullscreen = true
      if (this.application.data.status === 'NOT_STARTED') {
        this.application.data.started_at = formatISO(new Date(Date.now()))
      }
      this.application.data.access_count++

      if (this.application.data.access_count > 1) {
        const when = formatISO(Date.now())
        const until = formatISO(Date.now())
        this.sendLogData({ type: 'EXAM_APPLICATION_COME_BACK', question: '', when, until, wording: '' })
      }
      this.toggle()
      const dataQuestions = ls.get(`questions/${this.application.id}`, { secret: this.application.id })
      this.dataQuestions = dataQuestions
      for (let i = 0; i < this.dataQuestions.questions.length; i++) {
        if (!this.dataQuestions.questions[i].wordings[0].answer.length) {
          this.activeQuestion = {
            ...this.dataQuestions.questions[i],
            when: formatISO(Date.now())
          }
          break
        }
        this.activeQuestion = {
          ...this.dataQuestions.questions[0],
          when: formatISO(Date.now())
        }
      }
      this.dataQuestions.questions.map((item, index) => {
        if (item.wordings[0].answer.length <= 1 || item.wordings[0].type === 'MATRIX') {
          item.wordings[0].answer = item.wordings[0].answer[0]
        }
        if (item.wordings[0].answer) {
          this.dataQuestions.resumeQuestions[index].answer = true
        }
      })

      this.questionProgress.total = this.dataQuestions.resumeQuestions.length
      this.questionProgress.solved = this.dataQuestions.questions.filter(
        (obj) => obj.wordings[0].answer
      ).length
      this.questionProgress.progress = 100 * (this.questionProgress.solved / this.questionProgress.total)
    },
    clickExit () {
      this.$emit('sendLogType')

      if (
        (this.application.access.has_limit &&
        this.application.data.access_count === this.application.data.exam_application.access_limit) ||
        this.dataQuestions.resumeQuestions.filter((obj) => obj.answer === true).length === this.dataQuestions.questions.length
      ) {
        this.dialogFinish = true
      } else {
        this.fullscreen = false
      }
    },
    recordExit () {
      this.$emit('sendLogType')
      const when = formatISO(Date.now())
      const until = formatISO(Date.now())
      this.sendLogData({ type: 'EXAM_APPLICATION_GET_OUT', question: '', when, until, wording: '' })

      if (this.fullscreen && this.activeExam) {
        this.fullscreen = false
      }
      if (this.activeExam) {
        if (this.application.access.has_limit) {
          if (this.application.data.access_count < this.application.exam_application.data.access_limit) {
            this.dialogWarningExit = true
            this.$emit('warningExit', true)
          } else {
            this.finishExam()
            this.dialogWarningExitLimit = true
            this.$emit('warningExitLimit', true)
          }
        }
        this.activeExam = false
      }
    },
    clickTitle (index) {
      this.changeQuestion(index)
      if (!this.mobile) {
        this.drawer = false
      } else {
        this.drawer = true
      }
    },
    syncAnswer () {
      try {
        this.sendAnswer(this.activeQuestion)
        this.dataQuestions.resumeQuestions[this.activeQuestion.order].lastSyncAnswer = formatISO(Date.now())
      } catch (error) {

      }
    },
    changeQuestion (index) {
      try {
        this.$emit('sendLogType')

        const when = this.activeQuestion.when
        const until = formatISO(Date.now())

        this.updateQuestionProgress()
        if (this.activeQuestion.wordings[0].type !== 'MULTIPLE_CHOICE') {
          this.sendAnswer(this.activeQuestion)
        }

        this.activeQuestion = {
          ...this.dataQuestions.questions[index],
          when: until
        }
        this.sendLogData({ question: this.activeQuestion.id, when, until, wording: this.activeQuestion.wordings[0].id, type: 'WORDING_DISPLAY' })
      } catch (error) {

      }
    },
    finishExam () {
      this.fullscreen = false
      this.activeExam = false
      this.dialogFinish = false
      this.setLoading(true)

      this.application.data.status = 'ENDED'
      ls.set(`exam/${this.application.id}`, this.application, { secret: this.application.id })

      this.$axios
        .post(`/organizations/${this.id}/students/exams-applications/${this.dataQuestions.student.metadata.id}/finish`)
        .then(response => {
          if (response.data.success) {
            this.$store.dispatch('alert', {
              color: 'green',
              msg: response.data.message
            })
            this.application = null
          }
        })
        .catch(error => {
          this.errorSendAnswer = true
          const errosArray = error.response.data.errors
          this.handleErrors(errosArray)
        })
        .finally(() => {
          this.setLoading(false)
          this.$router.push({ path: '/resolution/list' })
        })
    },
    gotoNextQuestion () {
      if (this.activeQuestion.order !== (this.questionProgress.total - 1)) {
        this.changeQuestion(this.activeQuestion.order + 1)
      } else {
        this.changeQuestion(0)
      }
    },
    gotoPreviousQuestion () {
      this.changeQuestion(this.activeQuestion.order - 1)
    },
    updateQuestionProgress () {
      this.questionProgress.solved = this.dataQuestions.questions.filter(
        (obj) => obj.wordings[0].answer
      ).length
      this.questionProgress.progress = 100 * (this.questionProgress.solved / this.questionProgress.total)
    }
  },
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.intervalSyncAnswer = setInterval(() => {
      if (this.dataQuestions && this.activeQuestion) {
        if (this.dataQuestions.resumeQuestions[this.activeQuestion.order].answer !== this.activeQuestion.wordings[0].answer) {
          this.syncAnswer()
        }
      }
    }, 10 * 1000)
    this.mobile = !this.$vuetify.breakpoint.mobile
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.recordExit()
      }
    })
  },
  beforeDestroy () {
    clearInterval(this.intervalSyncAnswer)
  },

  watch: {
    '$vuetify.breakpoint.mobile' (val) {
      this.mobile = !val
      this.drawer = true
    },
    activeQuestion (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.activeResumeQuestion = this.activeQuestion.order
      }
    },
    online (newValue, oldValue) {
      if (!newValue && oldValue) {
        this.offlineStart = Date.now()
      }
      this.snackbarOff = !newValue
      this.snackbarOn = newValue
      if (newValue && !oldValue) {
        this.nowOnline()
      }
    }
  }
}
</script>

<style>
#resolution-app{
  z-index: 9999999;
}
</style>
